import React from 'react';
import About from './About/About';
import Contact from './Contact/Contact';
import FAQ from './FAQ/FAQ';
import Features from './Features/Features';
import Home from './Home/Home'
import Download from './Download/Download'

export default function Main() {
  return <div className='main'>
      <Home />
      <Features />
      <About />
      <FAQ />
      <Download />
      <Contact />
  </div>;
}

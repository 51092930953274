import React, { useState } from 'react';
import arrowDown from '../../../images/landing/arrowDown.svg'
import linef from '../../../images/landing/linef.svg'

const Accordion = ({num, title, p1, p2, p3 }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>
            <div className='number'>{num}</div>
            <div className='title'>{title}</div>
        </div>
        <div><img src={arrowDown} alt="" className={isActive ? 'down' : 'up'}/></div>
      </div>
      {isActive && <div className="accordion-content">
        <div style={{display: 'flex', alignItems: 'flex-start', marginBottom: '20px'}}><img src={linef} alt="" /><p style={{margin: "0", fontSize: '12px', textAlign: 'left', marginTop: '-6px', marginLeft: '8px'}}>{p1}</p></div>
        {p2 && <div style={{display: 'flex', alignItems: 'flex-start', marginBottom: '20px'}}><img src={linef} alt="" /><p style={{margin: "0", fontSize: '12px', textAlign: 'left', marginTop: '-6px', marginLeft: '8px'}}>{p2}</p></div>}
        {p3 && <div style={{display: 'flex', alignItems: 'flex-start', marginBottom: '20px'}}><img src={linef} alt="" /><p style={{margin: "0", fontSize: '12px', textAlign: 'left', marginTop: '-6px', marginLeft: '8px'}}>{p3}</p></div>}
        </div>}
    </div>
  );
};

export default Accordion;
import React from 'react';
import './styles.scss'
import fb from '../../images/landing/fb.svg'
import insta from '../../images/landing/insta.svg'
import twitter from '../../images/landing/twitter.svg'
import linkedin from '../../images/landing/linkedin.svg'
import footerLogo from '../../images/landing/footerLogo.svg'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import tc from '../../Terms_and_Conditions_of_Use_of_the_ExtraCard_Services.pdf'
import pp from '../../Privacy_Policy.pdf'

export default function Footer() {
  return (
    <div className='footer-wrapper' id='about'>
      <div className='footer-content' >
        <img src={footerLogo} alt="" />
        <p>FIGHT INFLATION AND UNCERTAINTY, <br />BUILD ANOTHER LINE OF FINANCIAL SAFETY!</p>
        <div className='links'>
        <HashLink smooth to="/#card">CARD</HashLink>
                  <HashLink smooth to="/#web3">WEB 3</HashLink> 
                  <Link to="/#dao">DAO</Link> 
                  <HashLink smooth to="/#faq">FAQ</HashLink>
                  <HashLink smooth to="/#contact">CONTACT</HashLink>
                  <HashLink smooth to="/#about">ABOUT</HashLink>
        </div>
        <div className='socials'>
        
 
          <a href="https://twitter.com/extracard2023" target="_blank" rel="noreferrer"> <img src={fb} alt="" /></a> 
          <a href="https://twitter.com/extracard2023" target="_blank" rel="noreferrer"> <img src={insta} alt="" /></a> 
          <a href="https://twitter.com/extracard2023" target="_blank" rel="noreferrer"> <img src={twitter} alt="" /></a> 
          <a href="https://twitter.com/extracard2023" target="_blank" rel="noreferrer"> <img src={linkedin} alt="" /></a> 
          {/* <img src={linkedin} alt="" /> */}
        </div>
        <div className='bottom'>
         <h6>Copyright © 2023 | <span>ExtraCard</span> All Rights Reserved</h6>
   
        </div>
        <div className='socials'>
       
          <h5 ><a  className='urlLin' href={tc} target='_blank'>Terms and conditions</a> - <a  className='urlLin'  href={pp} target='_blank'>Privacy</a> </h5>


        </div>
        <div className='lgl'>
        <h6> Extracard accounts and cards are issued by Paynovate under the MasterCard license and managed by LinkCy SAS. Paynovate is authorized by the National Bank of Belgium under the Electronic Money and Electronic Money Institutions Act (ref 0506763929) to issue electronic money and provide related payment services. LinkCy SAS is an agent of Paynovate, registered with NBB under number 852295732.</h6>
        </div>
      </div>
    </div>
  )
}

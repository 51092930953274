import React, {useState, useEffect} from 'react';
import faqT from '../../../images/landing/faqT.svg'
import Accordion from './Accordion';
import { accordionData1, accordionData2 } from './content';
import './FAQ.scss'

export default function FAQ() {
    const [faq1Clicked, setFaq1Clicked] = useState(false);
    const [faq2Clicked, setFaq2Clicked] = useState(false);
    const [faq3Clicked, setFaq3Clicked] = useState(false);
    const [faq4Clicked, setFaq4Clicked] = useState(false);
    const [faq5Clicked, setFaq5Clicked] = useState(false);

    useEffect(() => {
        if(faq1Clicked === true){
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq1Clicked]);

      useEffect(() => {
        if(faq2Clicked === true){
            setFaq1Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq2Clicked]);

      useEffect(() => {
        if(faq3Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq4Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq3Clicked]);

      useEffect(() => {
        if(faq4Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq5Clicked(false)
        }
      }, [faq4Clicked]);

      useEffect(() => {
        if(faq5Clicked === true){
            setFaq1Clicked(false)
            setFaq2Clicked(false)
            setFaq3Clicked(false)
            setFaq4Clicked(false)
        }
      }, [faq5Clicked]);

  return <div className='faq-wrapper' id="faq">
      <div className='faq-container'>
            <img src={faqT} alt="" />
            <div style={{display: 'flex', width: '100%'}} className="content">
                <div className="accordion1">
                    {accordionData1.map(({ num, title, p1, p2, p3 }) => (
                    <Accordion num={num} title={title} p1={p1} p2={p2} p3={p3}/>
                    ))}
                </div>
                <div className="accordion2">
                    {accordionData2.map(({ num, title, p1, p2, p3 }) => (
                    <Accordion num={num} title={title} p1={p1} p2={p2} p3={p3}/>
                    ))}
                </div>
            </div>
            
            
      </div>
  </div>;
}

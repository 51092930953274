import React, {useEffect, useState} from 'react';
import './Navbar.scss';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/landing/logo2.png'
import { Link } from 'react-router-dom';

function Navbar() {
  const [nav, setNav] = useState(false);   
  
  const changeBackground = () => {
      if (window.scrollY >= 100) {
        setNav(true);
      } else {
        setNav(false);
      }
    };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

    return (
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom"className={nav ? 'navbar-wrapper active' : 'navbar-wrapper'}>
            <div className='navbar-container'>
                <div data-aos="zoom-in">
              <Link to="/"><img src={logo} alt="" /></Link>  
                </div>

                <div>
                  <HashLink smooth to="/#card">CARD</HashLink>
                  <HashLink smooth to="/#web3">WEB 3</HashLink> 
                 
                  <HashLink smooth to="/#dao">DAO</HashLink>
                  <HashLink smooth to="/#faq">FAQ</HashLink>
                  <HashLink smooth to="/#contact">CONTACT</HashLink>
                  <Link to="/payment">ENROLLMENT</Link>
               
                  <Link to="/#download" ><button><HashLink smooth to="/#download">DOWNLOAD</HashLink></button></Link>
                 
                </div>
            </div>
        </div>
    )
}

export default Navbar

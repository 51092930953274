import React, { useEffect, useState } from 'react'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import configData from '../../config.json';
import { Alert, CircularProgress, } from '@mui/material';
import './styles.scss'
import { useNavigate } from 'react-router-dom';
// import useAuth from '../../hooks/useAuth';
// import { _deleteSession } from '../../redux/slices/authJwt';

export default function Stripe({ phone, plan, processing, disabled, handleChange, setProcessing, setError, succeeded, setSucceeded, amount, setAmount, error, email}) {
  const stripe = useStripe(); 
  const elements = useElements();
  const navigate = useNavigate();



  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    let paymentIntents = null;
 
    try {
      /* Stripe allow only integer value in price so need to change your price (amount) into cent by (*100 ) */
   

      const res = await  axios.post(configData.SERVER_URL + "/game/createPaymentIntentCard", {
        email:email,
        phone: phone,
        plan: plan,

      });
      if (!res || !res.data) {
        throw new Error("Error from payment server");
      }

      /*  if (res.data.error !== 0) {
        throw new Error(res.data.message);
      } */
      paymentIntents = res.data;

      if (paymentIntents) {
        const cardEl = elements.getElement(CardElement);
        const clSecret = paymentIntents.clientSecret;

        const payload = await stripe.confirmCardPayment(clSecret, {
          payment_method: {
            card: cardEl,
          },
        });

        if (payload.error) {
          throw new Error(`Payment failed : ${payload.error.message}`);
        }
        setSucceeded(true);
        setError(null);
        setProcessing(false);
        setAmount(0);
        cardEl.clear();
        // setTimeout(() => {
        //   setSucceeded(false);
        // }, 2000);
      }

      setProcessing(false);
    } catch (error) {
      if(error?.request?.status === 477){
        // _deleteSession();
        navigate('/', { replace: true });
       }
      setProcessing(false);
      setError(error.message);
    }
  };
    
    const cardStyle = {
        style: {
          base: {
            color: "black",
            fontFamily: "Bios",
            fontSize: "14px",
            "::placeholder": {
              color: "rgba(255, 255, 255, 0.4)",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
      };
    
     
  return (
    <div style={{marginBottom: 150}}>
        <form id="payment-form" onSubmit={handleSubmit}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
      
        
          <input
            className='stripeInput'
            type="text"
            onFocus={(e) => (e.target.value = "")}
            placeholder="Card Holder"
           
          />
      
              <CardElement
              className='stripeInput'
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
          
      
      
        {error && (
          <Alert severity="error">
            {error}
          </Alert>
        )}
        {/* Show a success message upon completion */}
        {succeeded && (
          <Alert severity="success">
            {"Payment succeeded"}
          </Alert>
        )}
        <div className="col">
          <button
            type="submit"
            className={disabled ? "disabled-btn" :"action-btn"}
            disabled={processing || disabled || succeeded}
          >
            <span style={{width: 130}}>
              <div className="spinner" id="spinner"></div>
              {processing ? (
               
                  <CircularProgress size={15} color="inherit"/>
             
              ) : (
                <>{"Pay now"}</>
              )}
            </span>
           
          </button>
        </div>
      
      </div>
    </form>
    </div>
  )
}

import React, { useState } from 'react'
import fT1 from '../../../images/landing/fT1.svg'
import fT2 from '../../../images/landing/fT2.svg'
import i1 from '../../../images/landing/g1.svg'
import i2 from '../../../images/landing/g2.svg'
import i3 from '../../../images/landing/g3.svg'
import i4 from '../../../images/landing/g4.svg'
import i5 from '../../../images/landing/g5.svg'
import i6 from '../../../images/landing/g6.svg'

import './styles.scss'


export default function Features() {
  const [open, setOpen] = useState('');

  const unhover = () => {
    setOpen('')
  }

  return (
    <div className='features-wrapper' id='web3' >
      <div className='features-container'>
        <img src={fT1} alt="" />
        <div>
         <div onMouseOver={() => setOpen('one')} onMouseOut={unhover}>
           <div>
              <img src={i1} alt="" />
              <h5>A CLASSICAL ACCOUNT</h5>
              <h6>Mobile App on iPhone and Android for European users</h6>
              <p>With IBAN and MasterCard cards (physical+virtual)</p>
              <p>Premium account 199€ / year</p>
           </div>
         </div>

         <div onMouseOver={() => setOpen('two')} onMouseOut={unhover}>
           <div className='shadow'>
              <img src={i2} alt="" />
              <h5>THAT MAKES YOU EARN NFTS AND TOKENS</h5>
              <h6>1 NFT guaranteed per year</h6>
              <p>Plus monthly tickets for free Lotteries to earn even more</p>
              <p>Plus Reward Points to choose more NFTs and Tokens from the Reward Shop</p>
           </div>
         </div>

         <div onMouseOver={() => setOpen('three')} onMouseOut={unhover}>
           <div>
              <img src={i3} alt="" />
              <h5>AND FREE NFTS WITH ROYALTIES FOR THE FIRST SUBSCRIBERS</h5>
              <h6>Free mint of Genesis NFTs with royalties on ExtraCard income</h6>
              <p>Normal % for the first 10,000 clients</p>
              <p>Additional % for the first 1,000 clients</p>
           </div>
         </div>
         </div>
         <img src={fT2} alt="" />
         <div>
         <div onMouseOver={() => setOpen('four')} onMouseOut={unhover}>
           <div>
              <img src={i4} alt="" />
              <h5>JUST BY BEING A SUBSCRIBER, YOU INVEST IN WEB3</h5>
              <h6>No fees after the subscription, you build your
                portfolio for free</h6>
                              <p>Some of these projects and NFTs will
                become very valuable</p>
                <p>And you will also earn some cryptocurrencies
                airdrops and rewards</p>
           </div>
         </div>

         <div onMouseOver={() => setOpen('five')} onMouseOut={unhover}>
           <div className='shadow'>
              <img src={i5} alt="" />
              <h5>THEN DISCOVER AND LEARN ABOUT GOOD WEB3 PROJECTS</h5>
              <h6>ExtraCard selects the projects and explains why they were selected</h6>
              <p>You get education and information on Web3 and Metaverse opportunities</p>
              <p>And join a community where information and good projects are shared</p>
           </div>
         </div>

         <div onMouseOver={() => setOpen('six')} onMouseOut={unhover}>
           <div>
              <img src={i6} alt="" />
              <h5>AND BEGIN TO PLAY AND BUILD IN THE METAVERSE!</h5>
              <h6>You get early access and exclusive perks on various games</h6>
              <p>From little games to earn Rewards Points to Web3 Play to Earn games</p>
              <p>And Metaverse assets like virtual houses or apartments</p>
           </div>
         </div>
         </div>
      </div>
    </div>
  )
}

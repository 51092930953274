import './Payment.scss';
import React, { useEffect, useRef, useState } from 'react'
import { loadStripe } from "@stripe/stripe-js";
import {  CircularProgress} from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { Elements } from '@stripe/react-stripe-js';
import Stripe from '../../components/stripeElement/Stripe'

export default function Payment() {
    const [phone, setUserPhone] = useState("");
    const [email, setUserEmail] = useState("");
    const [plan, setPlan] = useState('premium');
  
    const handleChangePlan = (event) => {
      setPlan(event.target.value);
    };
    const [showPaymentMenu, setShowPaymentMenu] = useState(false);
    const [showInfoMenu, setShowInfoMenu] = useState(true);
    const myRef = useRef(null)
    const refInfo = useRef(null)
  
    const [loading, setLoading] = useState(false);
    const [stripeObject, setStripeObject] = useState(null);
    const [succeeded, setSucceeded] = useState(false);
      const [error, setError] = useState(null);
      const [processing, setProcessing] = useState("");
      const [amount, setAmount] = useState(199);
      const [disabled, setDisabled] = useState(true);
      const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const onSubmit = async() => {
      setLoading(true)
     if(phone && email){
          setShowPaymentMenu(true);    
     }
     setLoading(false);
      }
      useEffect(() => {
        executeScroll ()
      }, [showPaymentMenu]);
      const executeScroll = () => myRef.current.scrollIntoView({ block: 'center',  behavior: 'smooth' }) 

      const handleClickBuy = (e) => {
        setPlan(e);
        refInfo?.current?.scrollIntoView({ block: 'center',  behavior: 'smooth' }) ;
      }
  
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    //STRIPE PAYMENT
  
    useEffect(() => {
      document.body.style.overflow = 'unset';
    //   console.log("    test:",    )
     // console.log("    process.env.REACT_APP_STRIPE_PUBLIC_KEY:",    process.env.REACT_APP_STRIPE_PUBLIC_KEY)
      const fetchStripeObject = async () => {
        const res = await loadStripe(
          "pk_live_51MP2MnHQKcZF7P8qPt3QJeYAANxpK2xthsQWCRIsTALEDRmw6wkzNsUxzqqvP0GDjrhAWzZNZ0rXh4omujpBrptm00ulv92uHU"
        );
        if (res) {
          setStripeObject(res);
        }
      };
      fetchStripeObject();
    }, [showPaymentMenu]);
  
    const handleChange = (event) => {
      // Listen for changes in the CardElement
      // and display any errors as the customer types their card details
      setDisabled(event.empty);
      setError(event.error ? event.error.message : "");
    };
  

  return (
    <>
            
    {(showInfoMenu && !succeeded) &&(
    <div className='payment-wrapper'>
          <div className='plan-wrapper' >
      <div className='plan-container'>
        <h1>PRICING PLANS</h1>
        <div>
         <div>
           <div>
              <h5>Basic</h5>
              <h6><span>149€</span>/year</h6>
              <div className='list'>
                <p>Bet on your luck</p>
                <p>No guaranteed NFT</p>
                <p>10 Lottery Tickets/month</p>
                <p>No Booster</p>
                <p>IBAN + Mastercard <br /> Debit card</p>
              </div>
             
              <button onClick={()=>handleClickBuy("basic")}>BUY NOW</button>
           </div>
         </div>

         <div>
           <div className='shadow'>
              <h5>Premium</h5>
              <h6><span>199€</span>/year</h6>
              <div className='list'>
                <p>Play safe</p>
                <p>1 NFT guaranteed/year</p>
                <p>20 Lottery Tickets/month</p>
                <p>No Booster</p>
                <p>IBAN + Mastercard <br /> Debit card</p>
                <p>Recommended</p>
              </div>
              
             <button onClick={()=>handleClickBuy("premium")}>BUY NOW</button>
           </div>
         </div>

         <div>
           <div>
              <h5>Ultimate</h5>
              <h6><span>399€</span>/year</h6>
              <div className='list'>
                <p>All-in</p>
                <p>3 NFTs guranteed/year</p>
                <p>50 Lottery Tickets/month</p>
                <p>1 Booster/month</p>
                <p>IBAN + Mastercard <br /> Debit card</p>
              </div>
              
             <button onClick={()=>handleClickBuy("ultimate")}>BUY NOW</button>
           </div>
         </div>
         </div>
        
    
      </div>
    </div>
                <div className="container-top" id='buy'>
                            <h6 ref={refInfo}>Informations</h6>

                                <form action="#">
                                        <div>
                                            <label>Your email</label>
                                            <input type="email" value={email} onChange={(e)=>setUserEmail(e.target.value)}/>
                                        </div>
                                        <div>
                                            <label >Your phone number</label>
                                            <PhoneInput
                                                className="form-control telInput"
                                                placeholder="Enter phone number"
                                                value={phone}
                                                defaultCountry="FR"
                                                onChange={setUserPhone}
                                                style={{backgroundColor: "transparent"}}/>
                                            {/* <input className="form-control" type="tel" onChange={(e)=> {setUserPhone(e.target.value)}} maxLength="20"  pattern="[0-9]{10}" /> */}
                                        </div>
                                    <div>
                                      <label>Which plan?</label>

                                        <select
                                        className="plan-select"
                                          value={plan}
                                          onChange={handleChangePlan}
                                        >
                                          <option value="basic">Basic (149€)</option>
                                          <option value="premium">Premium (199€)</option>
                                          <option value="ultimate">Ultimate (399€)</option>
                                        </select>
                                    </div>
                                    <div style={{alignItems:"center"}}>
                                        <button className="action-btn" onClick={onSubmit} disabled={loading} type="button" value="Submit"><span style={{width: 130}}>{loading ? <CircularProgress size={15}/> : <>{"Following"}</>}</span></button> 
                                    </div>
                                </form>
                </div>

            <div ref={myRef} >
            {showPaymentMenu  &&(
                      <div className="payment-menu" >
                 { plan === "basic" ? <h5 className="heading" style={{marginBottom: 50, fontSize: 30}}>Bank payment (149€)</h5> : plan === "premium" ? <h5 className="heading" style={{marginBottom: 50, fontSize: 30}}>Bank payment (199€)</h5> : <h5 className="heading" style={{marginBottom: 50, fontSize: 30}}>Bank payment (399€)</h5>}
              
                        {stripeObject && (
                                <Elements stripe={stripeObject}>    
                                  <Stripe email={email} phone={phone} plan={plan} handleChange={handleChange} setProcessing={setProcessing} error={error} setError={setError} succeeded={succeeded} setSucceeded={setSucceeded} amount={amount} setAmount={setAmount} processing={processing} disabled={disabled}/>
                                </Elements>
                              )}    
                            
                  </div>
                  )} 
            </div>
    
        
    </div>
    )}
        {
          succeeded && (
                <div className='payment-wrapper'>
                    <div style={{alignItems: 'center', justifyContent: 'center'}}>
                        <h1 style={{textAlign: 'center'}}>Thank you for your purchase !</h1>
                    </div>
                </div>
          )

        }


    </>
  );
}

import React from 'react'
import logo from '../../../images/landing/log_appstore.png'
import './styles.scss'

export default function Download() {
  return (
    
    <div className='download-wrapper' id="download">
     
        <div className='download-container'>
            <div className='test'>
            <div className='left'>
           
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.linkcy_extracard'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
            <div className='right'>
                <a target="_blank" href='https://apps.apple.com/us/app/extracard/id6443817092'><img alt='Get it on Apple Store' src={logo}/></a>

            </div>
            </div>
            

         
            
        </div>
        
    </div>
    
  )
}
import React, { useEffect, useCallback, useState } from "react";
import contactT from '../../../images/landing/contactT.svg'
import contact1 from '../../../images/landing/contact1.svg'
import contact2 from '../../../images/landing/contact2.svg'
import contact3 from '../../../images/landing/contact3.svg'
import contact4 from '../../../images/landing/contact4.svg'
import line from '../../../images/landing/line.png'
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'

import './styles.scss'


export default function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

  function  onNameChange(event) {

        setName(event.target.value)
      }
      function    onEmailChange(event) {
        setEmail(event.target.value)
      }
      function      onMessageChange(event) {
        setMessage(event.target.value)      }
      function     handleSubmit(event) {
       
        console.log(name,email,message)
        var templateParams = {
            name: name,
            email: email,
            message:message
        };
        emailjs.send("service_imovbgg", "template_t9la0h9", templateParams,"SO7kZCwH5TUpD4VNR")
        .then((result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully"
          })
        }, (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Ooops, something went wrong",
            text: error.text,
          })
        });
        event.preventDefault();
      event.target.reset()
    };
       
    
      
  return  <div className='contact-wrapper' id="contact">
    
      <div className='contact-container'>   
          <img src={contactT} alt="" />
          <div>
            <div className='left'>
                {/* <div>
                    <img src={contact1} alt="" />
                    <div>
                        <h4>Call Us</h4>
                        <img src={line} alt="" />
                        <p>+990123456789</p>
                    </div>
                </div> */}
                <div>
                    <img src={contact2} alt="" />
                    <div>
                        <h4>Email Us</h4>
                        <img src={line} alt="" />
                        <p>info@extracard.io</p>
                    </div>
                </div>
                <div>
                    <img src={contact3} alt="" />
                    <div>
                        <h4>Website</h4>
                        <img src={line} alt="" />
                        <p><a href='https://extracard.io'>extracard.io</a></p>
                    </div>
                </div>
                <div>
                    <img src={contact4} alt="" />
                    <div>
                        <h4>Address</h4>
                        <img src={line} alt="" />
                        <p>StartupLinkers LTD, 71-75 Shelton <br />Street, Covent Garden, London,<br /> England, WC2H 9JQ</p>
                    </div>
                </div>
            </div>
            <div className='right'> 
                <form  onSubmit={handleSubmit.bind(this)} >
                    <p>Your Full Name</p>
                    <input value={name} onChange={onNameChange.bind(this)}  />
                    <p>Your Email</p>
                    <input value={email} onChange={onEmailChange.bind(this)}  />
                    <p>Message</p>
                    <textarea  value={message} onChange={onMessageChange.bind(this)}  />
                    <button type="submit">SEND MESSAGE</button>
                </form>
            </div>
        </div>
      </div>
  </div>;
}

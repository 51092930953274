import React from 'react';
import home from '../../../images/landing/home.png'
import './styles.scss'
import YoutubeEmbed from "../../../components/YoutubeEmbed";

export default function Home() {
  return  <div className='home-wrapper' id="card">
      <div className='home-container'>   
          {/* <img src={home} alt="" /> */}
          <YoutubeEmbed embedId="sW9F5ORsl6g" />
      </div>
  </div>;
}

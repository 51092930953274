import {
  Routes,
  Route,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import MobileNavbar from './components/MobileNavbar/MobileNavbar'
import Main from "./pages/Main/Main";
import Footer from "./components/Footer/Footer";
import Payment from "./pages/Payment/Payment";


function App() {
  return (
    <div className="App">
      <Navbar />
      <MobileNavbar />
      <Routes>
        <Route exact path="/payment" element={<Payment />} />
        <Route path="/" element={<Main />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

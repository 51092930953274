export const accordionData1 = [
    {
      num: '01',
      title: 'Why the name ExtraCard?',
      p1: `ExtraCard is part of ExtraVerse constellation.`,
      p2: 'Including Metaverses, Play 2 Earn games, Web3 services (like this one)',
      p3: 'Launched in early 2021, beginning with Earthium.io'
    },
    {
      num: '02',
      title: 'Who is the team?',
      p1: `CEO Frederic Dembak - Le Strat, https://www.linkedin.com/in/dembak/`,
      
      p2: 'Investor and Strategic Advisor Christophe Connille - https://www.linkedin.com/in/christophe-connille/',

    },
    {
      num: '03',
      title: 'How many people are working on the project?',
      p1: 'In ExtraVerse constellation, all included, around 150 persons',
      p2: 'Representing more than 50 nationalities from all continents',
      p3: 'From 18 to 74 years old'
    },
    {
        num: '04',
        title: 'Who are the main partners and technical providers?',
        p1: `ExtraVerse is supported by Paris Stock Exchange innovation cluster`,
        p2: 'Blockchain-based services mainly powered by https://www.starton.io/',
        p3: 'Bank-as-a-service and mobile apps supplied by https://linkcy.io/'
      },
      {
        num: '05',
        title: 'Why is it a good idea to subscribe right now?',
        p1: `War escalating. Inflation, just beginning. Economic crisis coming.`,
        p2: 'Will you still have your job in 3 months? Not sure.',
        p3: 'Will your savings still be worth something? Not sure.'
      },
     
  ];

  export const accordionData2 = [
    {
        num: '06',
        title: 'Why is it better than directly investing in NFTs?',
        p1: `Because it's a tricky investment.`,
        p2: 'Lot of options, some good, some catastrophic. Lot of scams too.',
        p3: 'With ExtraCard, you invest and discover without taking any risk!'
      },
      {
        num: '07',
        title: 'Why NFTs and crypto? Why not only stocks, gold, etc?',
        p1: `Stock exchange is a bubble and is expected to crumble.`,
        p2: 'Real estate is a bubble too, and inflation affects your ROI.',
        p3: 'Physical gold price is manipulated, and transfers are regulated.'
      },
      {
        num: '08',
        title: 'Let\'s talk about rse, social justice, impact?',
        p1: `All ExtraVerse companies are Mission Purpose Companies`,
        p2: 'Meaning that we allocate 40% of our available income to the Mission',
        p3: 'And ExtraCard mission is to support Metaverse and Web3 development!'
      },
      {
        num: '09',
        title: 'To conclude, what is the roadmap?',
        p1: `2023 ExtraCard, providing a debit card and building the Tribes`,
        p2: '2024 Blue Chips NFTs mortgage credit, to build Metaverse Credit Score',
        p3: '2025 ExtraBank, the Bank of Metaverse, with loans for Web3 entrepreneurs'
      }
  ]
import React from 'react'
import about from '../../../images/landing/about.svg'
import './styles.scss'

export default function About() {
  return (
    <div className='about-wrapper' id="dao">
        <div className='about-container'>
            <div className='left'>
                <h2>WEB3 SPIRIT AND BEST <br />PRACTICES: POWER <br />BACK TO COMMUNITIES</h2>
                <h4>A CLASSICAL PLAYER ABIDING BY DAO <br /> RULES</h4>
                <p>You choose a Tribe at the subscription to support the related project</p>
                <p>A DAO will choose the projects to fund and split the funds</p>
                <p>The more people in your Tribe, the more votes each of you have</p>
            </div>
            <div className='right'>
                <img src={about} alt="" />
            </div>
        </div>
    </div>
  )
}